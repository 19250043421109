import React from 'react';
import axios from 'axios';
import {
    Nav,
    NavItem,
    NavLink,
    Navbar,
    NavbarBrand,
    Collapse,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    Input
} from 'reactstrap';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../assets/images/logo-icon.png';
import logolighticon from '../../assets/images/logo-light-icon.png';
import logodark from '../../assets/images/Logo-cloud9security-dark.png';
import logoligh from '../../assets/images/Logo-cloud9security-light.png';
import profilephoto from '../../assets/images/users/1.jpg';

const currentAxios = axios.create({ baseURL: "https://msal-api.iwowsoft.team" })

const Header = () => {

    const showMobilemenu = () => {
        document.getElementById('main-wrapper').classList.toggle('show-sidebar');
    }

    /*--------------------------------------------------------------------------------*/
    /*To open Search Bar                                                              */
    /*--------------------------------------------------------------------------------*/
    const toggleMenu = () => {
        document.getElementById('search').classList.toggle('show-search');
    }

    const callingGatewayLogin = async () => {
        console.log("start calling api gateway now");

        await currentAxios.
            post('/basic-login', {userName:'chua', password: '1234'}).
            then((response) => {
                console.log('basic-login response', response)

                console.log('basic-login header data', response.data)
                console.log('basic-login header content-type', response.headers['content-type'])
                console.log('basic-login header secret-token', response.headers['secret-token'])
                console.log('basic-login header content-encoding', response.headers['content-encoding'])
                console.log('basic-login header set-cookie', response.headers['set-cookie'])
                console.log('basic-login header cookie', response.headers['cookie'])
            }).
            catch((error) => {
                console.log('basic-login failed' + error);
            });

        await currentAxios.
            get('/is-login-valid').
            then((response) => {
                console.log('is-login-valid success', response)
            }).
            catch((error) => {
                console.log('is-login-valid failed' + error);
            });
    }

    const callingOffice365Login = async () => {
        console.log("start calling o365 auth now");

        await currentAxios.
            get('').
            then((response) => {
                console.log('o365-login response', response)

                console.log('o365-login header data', response.data)
                console.log('o365-login header content-type', response.headers['content-type'])
                console.log('o365-login header secret-token', response.headers['secret-token'])
                console.log('o365-login header content-encoding', response.headers['content-encoding'])
                console.log('o365-login header set-cookie', response.headers['set-cookie'])
                console.log('o365-login header cookie', response.headers['cookie'])
            }).
            catch((error) => {
                console.log('o365-login failed: ' + error);
            });

        await currentAxios.
            get('/is-login-valid').
            then((response) => {
                console.log('is-login-valid success', response)
            }).
            catch((error) => {
                console.log('is-login-valid failed' + error);
            });
    }

    return (
        <header className="topbar navbarbg" data-navbarbg="skin4">
            <Navbar className="top-navbar" dark expand="md">
                <div className="navbar-header" id="logobg" data-logobg="skin4">
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
                    {/*--------------------------------------------------------------------------------*/}
                    <NavbarBrand href="/">
                        {/*
                        <b className="logo-icon">
                            <img src={logodarkicon} alt="homepage" className="dark-logo" />
                            <img
                                src={logolighticon}
                                alt="homepage"
                                className="light-logo"
                            />
                        </b>
                        */}
                        <span className="logo-text">
                            <img src={logodark}  alt="Home" className="dark-logo" />
                            <img src={logoligh} alt="Home" className="light-logo" />
                        </span>
                    </NavbarBrand>
                    {/*--------------------------------------------------------------------------------*/}
                    {/* Mobile View Toggler  [visible only after 768px screen]                         */}
                    {/*--------------------------------------------------------------------------------*/}
                    <button
                        className="btn-link nav-toggler d-block d-md-none text-white"
                        onClick={() => showMobilemenu()}
                    >
                        <i className="ti-menu ti-close" />
                    </button>
                </div>
                <Collapse
                    className="navbarbg"
                    navbar
                    data-navbarbg="skin4"
                >
                    <Nav className="float-left" navbar>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Start Search-box toggle                                                        */}
                        {/*--------------------------------------------------------------------------------*/}
                        <NavItem className="hidden-sm-down search-box">
                            <NavLink
                                href="#"
                                className="hidden-sm-down"
                                onClick={toggleMenu.bind(null)}
                            >
                                <i className="ti-search" />
                            </NavLink>
                            <Form className="app-search" id="search">
                                <Input type="text" placeholder="Search & enter" />
                                <button className="btn-link srh-btn" onClick={toggleMenu.bind(null)}>
                                    <i className="ti-close" />
                                </button>
                            </Form>
                        </NavItem>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Search-box toggle                                                          */}
                        {/*--------------------------------------------------------------------------------*/}
                    </Nav>
                    <Nav className="ml-auto float-right" navbar>
                        {/*
                        <NavItem>
                            <a
                                href="https://www.wrappixel.com/templates/materialpro-react-admin/"
                                className="btn btn-danger mr-2"
                                style={{ marginTop: '20px' }}
                            >
                                Upgrade to Pro
                            </a>
                        </NavItem>
                        */}
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Start Profile Dropdown                                                         */}
                        {/*--------------------------------------------------------------------------------*/}
                        <UncontrolledDropdown nav inNavbar>
                            <DropdownToggle nav caret className="pro-pic">
                                <img
                                    src={profilephoto}
                                    alt="user"
                                    className="rounded-circle"
                                    width="31"
                                />
                            </DropdownToggle>
                            <DropdownMenu right className="user-dd">
                                <DropdownItem>
                                    <i className="ti-user mr-1 ml-1" /> My Account
                                </DropdownItem>
                                <DropdownItem>
                                    <i className="ti-wallet mr-1 ml-1" /> My Balance
                                </DropdownItem>
                                <DropdownItem className="border-bottom">
                                    <i className="ti-email mr-1 ml-1" /> Inbox
                                </DropdownItem>
                                <DropdownItem className="border-bottom">
                                    <i className="fas fa-user mr-1 ml-1" /> Account Settings
                                </DropdownItem>
                                <DropdownItem href="/pages/login">
                                    <i className="fa fa-power-off mr-1 ml-1" /> Logout
                                </DropdownItem>
                                <DropdownItem onClick={callingGatewayLogin.bind(null)}>
                                    <i className="fa fa-power-on mr-1 ml-1" /> Test Basic Auth Login
                                </DropdownItem>
                                <DropdownItem onClick={callingOffice365Login.bind(null)}>
                                    <i className="fa fa-power-on mr-1 ml-1" /> Test Office365 Login
                                </DropdownItem>
                                <DropdownItem>
                                    <a href="https://www.w3schools.com">Visit W3Schools.com!</a>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* End Profile Dropdown                                                           */}
                        {/*--------------------------------------------------------------------------------*/}
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    );
}
export default Header;
