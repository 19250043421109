import React, { useState } from 'react';
import ReactDOM from 'react-dom/server';
import {
    Card,
    CardImg,
    CardImgOverlay,
    CardText,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardColumns,
    CardGroup,
    CardDeck,
    CardLink,
    CardHeader,
    CardFooter,
    Button,
    Row,
    Col
} from 'reactstrap';
import {
    FileManagerComponent,
    Inject,
    NavigationPane,
    LargeIconsView,
    DetailsView,
    Toolbar,
  } from '@syncfusion/ej2-react-filemanager';

import styled from "styled-components";

import { Targets } from "../../_mock/targets";

//#region styled-components

const DivBox = styled.div`
    position: relative;
    border: 2px solid white;
    border-radius: 3px;
    flex: 1;
    margin: 8px 8px;
    background-color: white;
    box-shadow: 0 3.2px 7.2px 0
            var(--callout-shadow-color, rgba(0, 0, 0, 0.132)),
        0 0.6px 1.8px 0
            var(--callout-shadow-secondary-color, rgba(0, 0, 0, 0.108));
    padding: 0px 7px;
    transition: background-color 0.2s, border 0.2s, box-shadow 0.2s;

    ${({ isSplit }) => {
        if (isSplit) {
            return `height: 148px;`;
        } else {
            return `height: 188px;`;
        }
    }};

    ${({ selected, disableContentPage }) => {
        if (selected && !disableContentPage) {
            return `
            border: 2px solid #0095ff54;
            `;
        } else if (!selected && !disableContentPage) {
            return ` 
            cursor: pointer;
            &:hover {
                border: 2px solid #0095ff54;
                background-color: #f1f4f2;
            }`;
        }
    }};
`;

const DivRowCenter = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: inherit;
`;

const DivSubTitle = styled.div`
    background-color: inherit;
    padding-right: 5px;
    color: black;
    font-weight: 700;

    font-size: 27px;
    font-weight: 700;

    & > span {
        font-size: 19px;
        font-weight: 400;
        color: #0000007d;
        padding: 5px;
    }
`;

const DivStatus = styled.div`
    border: 1px solid transparent;
    width: 24px;
    padding: 4px 16px;
    font-size: 16px;
    text-align: center;
    margin-right: 7%;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const DivStatusCritical = styled(DivStatus)`
    background-color: #f44336;
    color: white;
`;

const DivStatusModerate = styled(DivStatus)`
    background-color: #ffb840;
    color: white;
`;
const DivStatusLow = styled(DivStatus)`
    background-color: #7ed3fc;
    color: white;
`;
//#endregion styled-components

const Target = (props) => {
    const { Url, Name, Description , Critical, Moderate, Low, Total, DateLastScan } = props.targetDetails;
    return(
        <Col>
            <Card>
                <CardHeader>{Url}</CardHeader>
                <CardBody>
                    {/*<CardTitle>{Name}</CardTitle>*/}
                    {/*<CardText>{Description}</CardText>*/}
                    <DivRowCenter
                        style={{
                            marginTop: "1px",
                        }}
                    >
                        <DivSubTitle className="divSubTitle">
                            {Total}
                            <span style={{ backgroundColor: "inherit" }}>
                                / {"Vulnerabilities"}
                            </span>
                        </DivSubTitle>
                    </DivRowCenter>
                    <DivRowCenter
                        style={{
                            marginTop: "5px",
                        }}
                    >
                        <DivStatusCritical className="divStatusAlert">
                            {Critical}
                        </DivStatusCritical>
                        <DivStatusModerate className="divStatusWarning">
                            {Moderate}
                        </DivStatusModerate>
                        <DivStatusLow className="divStatusInfo">
                            {Low}
                        </DivStatusLow>
                    </DivRowCenter>
                </CardBody>
                <CardFooter>Last scan: {DateLastScan}</CardFooter>
            </Card>
        </Col>
    );
}

const Starter = () => {    
    let serverUrl = 'https://do-client.iwowsoft.workers.dev/';
    let frontEndUrl = window.location.origin;

    function createElementFromHTML(htmlString) {
        var div = document.createElement('div');
        div.innerHTML = '<div class="row">' + htmlString.trim() + '</div>';
        div.style.width = '290px';
        div.style.height = '155px';

        // Change this to div.childNodes to support multiple top-level nodes
        return div; 
      }

    function onFileLoad(args) { 
        //console.log("File details : %o", args.fileDetails)
        if (args.module == "LargeIconsView" && args.fileDetails.type == "") { 
            args.element.firstElementChild.removeChild(args.element.querySelectorAll(".e-list-icon.e-fe-folder")[0]); 
            //ej.base.createElement
            var dynamicTargetHTML = ReactDOM.renderToStaticMarkup(
                        React.createElement(
                            Target, 
                            { targetDetails: args.fileDetails}
                        )
                    ); 

            args.element.firstElementChild.insertBefore(createElementFromHTML(dynamicTargetHTML), args.element.firstElementChild.querySelectorAll(".e-list-text")[0]); 
            //args.element.innerHTML = args.element.innerHTML.replace('<span class="e-frame e-icons"></span>', dynamicTargetHTML);
        } 
    } 

    return (
        <div>
            <h2 className="mb-3">TARGETS</h2>
            <FileManagerComponent
                id="overview_file"
                height="calc(100vh - 176px)"
                allowMultiSelection = {false}
                ajaxSettings={{
                url: serverUrl + 'Hovid/FileManager/FileOperations',
                //url:"https://ej2-aspcore-service.azurewebsites.net/api/FileManager/FileOperations"
                }}
                view={'Details'}
                navigationPaneSettings={{ visible: false }}
                toolbarSettings={{ items: ['Selection', 'Rename', 'SortBy', 'Delete', 'Refresh', 'View', 'Details'] }}
                contextMenuSettings={{
                    file: ['Details', '|', 'Delete', 'Rename', '|'],
                    folder: ['Details', '|',  'Delete', 'Rename', '|'],
                    layout: ['SortBy', 'View', 'Refresh', '|', 'Details', '|']
                }}
                detailsViewSettings={{
                    columns: [
                        { field: 'name', headerText: 'Target Name', minWidth: 120, width: 'auto', customAttributes: { class: 'e-fe-grid-name' }, template: '${name}' },
                        { field: 'Url', headerText: 'Url', minWidth: 100, width: '110', template: '${Url}' },
                        { field: 'Description', headerText: 'Description', minWidth: 120, width: '190', template: '${Description}' },
                        { field: 'Critical', headerText: 'Critical', minWidth: 60, width: 'auto', template: '${Critical}' },
                        { field: 'Moderate', headerText: 'Moderate', minWidth: 60, width: '110', template: '${Moderate}' },
                        { field: 'Low', headerText: 'Low', minWidth: 50, width: '110', template: '${Low}' },
                        { field: 'Total', headerText: 'Total', minWidth: 50, width: '110', template: '${Total}' },
                        { field: 'DateLastScan', headerText: 'Lasts scan date', minWidth: 50, width: '190', template: '${DateLastScan}' }
                    ]
                }}
                fileLoad = {onFileLoad}
                >
                <Inject services={[NavigationPane, LargeIconsView, DetailsView, Toolbar]} />
          </FileManagerComponent>

        </div>
    );
}

export default Starter;