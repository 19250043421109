/*--------------------------------------------------------------------------------*/
/*                                  starter                                    */
/*--------------------------------------------------------------------------------*/
import FirstDashboard from '../views/starter/starter.jsx';
/*--------------------------------------------------------------------------------*/
/*                           Ui-components Dropdown                               */
/*--------------------------------------------------------------------------------*/
import Scan from '../views/ui-components/scan.jsx';
import Findings from '../views/ui-components/findings.jsx';
import Settings from '../views/ui-components/settings.jsx';
import Cards from '../views/ui-components/cards.jsx';
import LayoutComponent from '../views/ui-components/layout.jsx';
import PaginationComponent from '../views/ui-components/pagination.jsx';
import PopoverComponent from '../views/ui-components/popover.jsx';
import TooltipComponent from '../views/ui-components/tooltip.jsx';

var ThemeRoutes = [
  {
    path: '/starter/starter',
    name: 'Dashboard',
    icon: 'mdi mdi-view-dashboard',
    component: FirstDashboard
  },
  {
    path: '/ui-components/alert',
    name: 'Scan',
    icon: 'mdi mdi-magnify',//'mdi mdi-comment-processing-outline',
    component: Scan
  },
  {
    path: '/ui-components/badge',
    name: 'Findings',
    icon: 'mdi mdi-bug',//'mdi mdi-arrange-send-backward',
    component: Findings
  },
  {
    path: '/ui-components/button',
    name: 'Settings',
    icon: 'mdi mdi-settings',//'mdi mdi-toggle-switch',
    component: Settings
  },/*
  {
    path: '/ui-components/card',
    name: 'Cards',
    icon: 'mdi mdi-credit-card-multiple',
    component: Cards
  },
  {
    path: '/ui-components/layout',
    name: 'Layout',
    icon: 'mdi mdi-apps',
    component: LayoutComponent
  },
  {
    path: '/ui-components/pagination',
    name: 'Pagination',

    icon: 'mdi mdi-priority-high',
    component: PaginationComponent
  },
  {
    path: '/ui-components/popover',
    name: 'Popover',

    icon: 'mdi mdi-pencil-circle',
    component: PopoverComponent
  },
  {
    path: '/ui-components/tooltip',
    name: 'Toltips',

    icon: 'mdi mdi-image-filter-vintage',
    component: TooltipComponent
  },*/
  {
    path: '/',
    pathTo: '/starter/starter',
    name: 'Dashboard',
    redirect: true
  }
];
export default ThemeRoutes;
