import React, { useState } from 'react';

const Scan = () => {
    // For Dismiss Button with Alert
    const [visible, setVisible] = useState(true);

    return (
            <div>
                <h2 className="mb-3">SCAN</h2>
            </div>
    );
}

export default Scan;
